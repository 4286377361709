/** 活动专场 */
<template>
  <div class="activity">
    <el-image class="IPBS-banner" :src="banner"></el-image>
    <!-- 近期活动 -->
    <section class="home-activity content">
      <div class="ipbs-title">
        <h4>近期活动</h4>
      </div>
      <el-row v-if="newActivity.length" :gutter="$isPc?20:0" :class="['activity-list', {'plr-10':!$isPc}]">
        <el-col class="activity-item mb-20" v-for="(item,index) in newActivity" :key="index">
          <div class="activity-item-con flex back-fff">
            <el-image class="activity-img flex-1" :src="$base+item.activity_logo" :fit="$isPc?'contain':'fill'">
            </el-image>
            <div class="activity-info flex-1">
              <h4 class="activity-title text-ellipsis mb-20">{{item.activity_name}}</h4>
              <el-row class="activity-info-list">
                <el-col class="activity-info-item text-ellipsis">
                  <el-image :src="require('@/assets/images/home-activity1.png')"></el-image>
                  {{item.activity_unit}}
                </el-col>
                <el-col :span="17" class="activity-info-item text-ellipsis">
                  <el-image :src="require('@/assets/images/home-activity2.png')"></el-image>
                  {{item.activity_address}}
                </el-col>
                <el-col class="activity-info-item text-ellipsis">
                  <el-image :src="require('@/assets/images/home-activity3.png')"></el-image>
                  {{item.activity_date}}
                </el-col>
              </el-row>
              <div :class="[$isPc?'activity-details-btn':'activity-details-text']">
                <router-link :to="{path:'/activity_details',query:{id:item.id}}">
                  <el-button type="primary"> 了解详情 </el-button>
                </router-link>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div v-else v-loading="newActivityLoading" class="loading-con"></div>
    </section>
    <!-- 往期活动 -->
    <section class="past-activity content">
      <div class="ipbs-title">
        <h4>往期活动</h4>
        <el-button v-if="!$isPc" type='text' @click="$router.push('/activity_list')">
          查看更多<i class="el-icon-arrow-right el-icon--right"></i>
        </el-button>
      </div>
      <template v-if="pastActivity.length">
        <el-row :gutter="$isPc?20:0" :class="['activity-list', {'plr-10':!$isPc}]">
          <el-col :xs="24" :sm="8" :lg="6" :class="['activity-item', {'mb-20':$isPc}]"
            v-for="(item,index) in pastActivity" :key="index">
            <router-link :to="{path:'/activity_details',query:{id:item.id}}" :class="{flex:!$isPc}">
              <el-image :class="['activity-img',{'flex-1':!$isPc}]" :src="$base+item.activity_logo" fit="cover">
              </el-image>
              <div :class="['activity-info', 'flex-column', $isPc?'justify-between':'ml-20 justify-around', 'flex-1']">
                <h4 class="activity-title text-ellipsis">{{item.activity_name}}</h4>
                <el-row :gutter="10" class="activity-info-list">
                  <el-col :xs="24" :sm="13" class="activity-info-item text-ellipsis">
                    <el-image :src="require('@/assets/images/home-activity2.png')"></el-image>
                    {{item.activity_address}}
                  </el-col>
                  <el-col :xs="24" :sm="11" class="activity-info-item text-ellipsis">
                    <el-image :src="require('@/assets/images/home-activity3.png')"></el-image>
                    {{item.activity_date|filterTime}}
                  </el-col>
                </el-row>
              </div>
            </router-link>
          </el-col>
        </el-row>
        <el-button v-if="$isPc" type="info" class="ipbs-more">
          <router-link to="/activity_list">查看更多</router-link>
        </el-button>
      </template>
      <div v-else v-loading="pastActivityLoading" class="loading-con"></div>
    </section>
  </div>
</template>
<script>
import { getNewActivity, getPastActivity } from '@/api/activity'
export default {
  name: 'Activity',
  data () {
    return {
      banner: this.$isPc ? require('@/assets/images/activity-banner1.png') : require('@/assets/images/activity-banner1-wap.png'),
      newActivity: [],
      pastActivity: [],
      newActivityLoading: true,
      pastActivityLoading: true,
    }
  },
  mounted () {
    this.getNewActivity()
    this.getPastActivity()
  },
  methods: {
    async getNewActivity () {
      try {
        let { data } = await getNewActivity()
        this.newActivity = data
        this.newActivityLoading = false
      } catch (error) { }
    },
    async getPastActivity () {
      try {
        let { data } = await getPastActivity()
        this.pastActivity = data
        this.pastActivityLoading = false
      } catch (error) { }
    }
  },
}
</script>
<style lang="scss" scoped>
.activity-item-con {
  box-shadow: 0px 0px 24px 0px rgba(52, 126, 237, 0.23);
}
</style>